<template>
  <div class="wrap">
    <div class="search-container">
      <el-form ref="form" :model="form" label-width="80px">
        <el-row>
          <el-col :span="10">
            <el-form-item label="验票时间">
              <el-date-picker
                style="width: 100%"
                clearable
                v-model="range"
                type="daterange"
                format="yyyy - MM - dd"
                value-format="yyyy-MM-dd"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('userName')">
              <el-input
                clearable
                placeholder="手机号/姓名"
                v-model="form.searchCon"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8" style="text-align: right">
            <el-button @click="searchEvent" class="search-button">{{
              $t("search")
            }}</el-button>
            <el-button @click="cleanForm" class="clean-button">{{
              $t("clean")
            }}</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="tab-container">
      <el-tabs
        class="taps-card"
        v-model="form.checkType"
        @tab-click="searchEvent"
      >
        <el-tab-pane label="预约" name="1"></el-tab-pane>
        <el-tab-pane label="票务" name="2"></el-tab-pane>
        <el-tab-pane label="课程" name="3"></el-tab-pane>
        <el-tab-pane label="赛事" name="4"></el-tab-pane>
      </el-tabs>
    </div>
    <div class="table-container">
      <el-table
        :data="tableData"
        :height="tableHeight - 20"
        style="width: 100%"
        border
        :row-class-name="tableRowClassName"
        @selection-change="handleSelectionChange"
      >
        <!-- <el-table-column
					type="selection"
          align="center"
					width="55">
				</el-table-column> -->
        <el-table-column type="index" align="center" label="序号" width="55">
        </el-table-column>
        <el-table-column
          prop="validationTime"
          align="center"
          width="185"
          label="验票时间"
        >
        </el-table-column>
        <el-table-column
          prop="qrCode"
          width="250"
          align="center"
          label="二维码"
        >
        </el-table-column>
        <el-table-column
          align="center"
          width="100"
          prop="customerNam"
          label="购票人"
        >
        </el-table-column>
        <el-table-column
          align="center"
          width="120"
          prop="phoneNum"
          label="联系电话"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="idCard"
          width="180"
          label="身份证号"
        >
        </el-table-column>
        <el-table-column
          align="center"
          width="220"
          prop="orderNum"
          label="订单号"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="orderDate"
          width="100"
          label="预约日期"
          :key="1"
          v-if="form.checkType === '1'"
        >
        </el-table-column>
        <el-table-column
          align="center"
          width="120"
          prop="timeSlot"
          label="预约时间"
          :key="2"
          v-if="form.checkType === '1'"
        >
        </el-table-column>
        <el-table-column
          align="center"
          width="200"
          prop="orderSite"
          label="预约场地"
          :key="3"
          v-if="form.checkType === '1'"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="timeSlot"
          width="160"
          label="消费时段"
          v-if="form.checkType === '2'"
        >
          <template slot-scope="scope">
            {{ scope.row.timeSlot === "" ? "不限" : scope.row.timeSlot }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          width="160"
          prop="deadLine"
          label="截止时间"
          show-overflow-tooltip
          v-if="form.checkType === '2'"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="tickInfo"
          width="120"
          label="票务信息"
          v-if="form.checkType === '2'"
        >
        </el-table-column>
        <el-table-column align="center" prop="price" label="金额">
        </el-table-column>
      </el-table>
    </div>
    <div class="page-container">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="form.pageNum"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="form.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="form.total"
      >
      </el-pagination>
    </div>
    <!-- <div class="button-container">
      <el-button class="default-button" @click="openDeleteDialog(selectedArr.map(item => item.userId))">{{$t('delete')}}</el-button>
      <el-button class="default-button" @click="changeAddDialog(true)">{{$t('add')}}</el-button>
    </div> -->
  </div>
</template>
<script>
import { mapState } from "vuex";
import apis from "@/apis";
import mixin from "@/mixins/index";
import TicketRecordSearchClass from "./ticketRecordSearchClass";

export default {
  mixins: [mixin],
  data() {
    return {
      form: new TicketRecordSearchClass("form"), // 实例化一个表单的变量
      tableData: [],
      // addDialog: {
      //   visible: false,
      //   isEdit: false,
      // },
      // confirmDialog: {
      //   visible: false,
      //   type: 1,
      //   data: {},
      // },
      range: [],
    };
  },
  computed: {
    ...mapState(["Language", "merchantAccountDetailInfoDTO"]),
  },
  methods: {
    /**
     * @function 搜索方法
     */
    searchData() {
      if (this.range.length) {
        [this.form.startTime, this.form.endTime] = this.range;
      }
      const formData = { ...this.form }
      formData.merchantId = this.merchantAccountDetailInfoDTO.merchantId
      this.$http.post(apis.checkInByTickNoInfo, formData).then((res) => {
        if (res.data.code === 0) {
          this.tableData = res.data.rows;
          this.form.total = res.data.total;
        }
      });
    },
    /**
     * @function 清空搜索栏
     */
    cleanForm() {
      this.form = new TicketRecordSearchClass("form");
      this.range = [];
      this.$nextTick(() => {
        this.searchEvent();
      });
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../../assets/styles/mixin.scoped.less";
.tab-container {
  margin: 0 20px;
}
</style>
<style lang="less">
@import "../../../assets/styles/mixin.less";
</style>
