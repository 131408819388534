import pageClass from '@/unit/pageClass'

class searchFrom extends pageClass {
  constructor(type) {
    super()
    if (type === 'form') {
      // this.YPSJ = ''
      // this.KHSJ = ''
      // this.ZT = '1'
      this.startTime = ''
      this.endTime = ''
      this.searchCon = ''
      this.checkType = '1'
    }
  }
}
export default searchFrom
